import  { useState} from 'react';
import './App.css';



import {AuthProvider,  UserManager} from 'oidc-react';

import AppContent from './AppContent';



function App() {


    const [userManager] = useState(new UserManager({ 
        redirect_uri: 'https://cloud.data2work.com', 
        // redirect_uri: 'http://localhost:3001', 
        client_id: 'postool',
        authority: 'https://auth.data2work.com/realms/d2wUser/',
        automaticSilentRenew: true,
        silent_redirect_uri: 'https://cloud.data2work.com',
        //silent_redirect_uri: 'http://localhost:3001'
        post_logout_redirect_uri: 'https://cloud.data2work.com'
    }))
        
        ;
  

    return (
        <AuthProvider userManager={userManager} autoSignIn={true}>
            <AppContent /> 
        </AuthProvider>
    );
}

export default App;
